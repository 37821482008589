import React from 'react';
import { Container, Row, Col, Badge, Toast } from 'react-bootstrap';
import DocumentListModal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Documento = ({ info, fontSize }) => {
    const [visible, setVisible] = React.useState(false);
    const [show, setShow] = React.useState(true);
    const toggleShow = () => setShow(!show);

    const handleFiles = () => {
        if(info.JAVASCRIPT !== undefined && info.JAVASCRIPT !== null && info.JAVASCRIPT !== '' && info.JAVASCRIPT !== ']' && info.JAVASCRIPT !== '[]'){
            const files = JSON.parse(info.JAVASCRIPT);
            const domFiles = files.map((file, index) => {
                return (
                    <div key={index + "_cont"}>
                        <Badge key={index + "_bad"} className="file-badge" variant="secondary">
                            <a key={index + "_a"} href={file.url} target="_blank" rel="noopener noreferrer">
                                {file.nombre}
                            </a>
                        </Badge>&nbsp;
                    </div>
                );
            });
            return domFiles;
        }
        return '';
    };
    return (
        <Container>
            <Row className='justify-content-md-center'>
                <Col lg={8} md={8} className='mb-2'>
                    <h1>{info.NOMBRE}</h1>
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                <Col md={8} className='mb-3 text-start'>
                    <button className='btn btn-secondary' onClick={() => setVisible(true)}>
                        <FontAwesomeIcon icon={faEnvelope} style={{fontSize: fontSize +'em'}} />&nbsp;
                        Solicitar más información
                    </button>
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                <Col lg={8} md={8} className='mb-2 content'>
                    <div className='content-text' style={{fontSize: fontSize +'em'}} dangerouslySetInnerHTML={{ __html: info.CONTENIDO }} />
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                <Col lg={8} md={8} className='mb-4'>
                    {(info.JAVASCRIPT !== undefined && info.JAVASCRIPT !== null && info.JAVASCRIPT !== '' && info.JAVASCRIPT !== ']' && info.JAVASCRIPT !== '[]')?
                    (<>
                        <h3 key="titulo_adjuntos">Adjuntos</h3>
                        <Toast show={show} onClose={toggleShow} delay={3000} autohide className='float-toast'>
                            <Toast.Header>
                                <strong className="me-auto">
                                    InfoHub
                                </strong>
                            </Toast.Header>
                            <Toast.Body>
                                Este documento contiene archivos para descarga.
                            </Toast.Body>
                        </Toast>
                    </>):('')}
                    {handleFiles()}
                </Col>
            </Row>
            <DocumentListModal visible={visible} setVisible={setVisible} titulo={info.NOMBRE} />
        </Container>
    );
};

export { Documento };