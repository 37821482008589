import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { NavBar } from "./modules/NavBar";
import { Home } from './modules/home/Home';
import { Info } from './modules/info/Info';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [fontSize, setFontSize] = React.useState(1);
  const [isValid, setIsValid] = React.useState(false);

  return (
    <>
      <HashRouter>
        <NavBar fontSize={fontSize} setFontSize={setFontSize} isValid={isValid} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="info/:code" element={<Info fontSize={fontSize} setIsValid={setIsValid} />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
