import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Semblanza } from './Semblanza';
import { Documento } from './Documento';
import config from './config';

const Info = ({fontSize, setIsValid}) => {
    const navigate = useNavigate();
    const { code } = useParams();
    //const apiBase = "";
    const apiBase = config.server;

    const [loading, setLoading] = React.useState(true);
    const [info, setInfo] = React.useState(null);

    const getClientData = async () => {
        let ip = '';
        let os = '';
        let browser = '';
        // Obtenemos la ip desde donde el cliente se conecta, también su sistema operativo y navegador, además la fecha y hora de la conexion
        await fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => ip = data.ip);
        os = window.navigator.platform;
        browser = window.navigator.userAgent;
        let date = new Date();
        let infodata = {
            'IP': ip,
            'SO': os,
            'CODE': code,
            'BROWSER': browser,
            'DATE': date
        };
        // post request enviando infodata a la api
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(infodata)
        };
        const response = await fetch(apiBase + 'api/infohub/postUserInfo', requestOptions);
        const data = await response.json();
        if(data != null){
            console.log(data);
        }
    };

    React.useEffect(() => {
        const getInfo = async () => {
            try {
                setLoading(true);
                // post request using fetch inside useEffect React hook
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'TOKEN': code })
                };
                const response = await fetch(apiBase + 'api/infohub/getInfo', requestOptions);
                const data = await response.json();
                if(data != null){
                    let fecha = new Date(data.data.FECHA);
                    // formato de fecha yyyy-mm
                    let mes = fecha.toLocaleString('default', { month: 'long' });
                    data.data.FECHA = mes + ' ' + fecha.getFullYear();
                    setInfo(data.data);
                }
                setLoading(false);
                setIsValid(true);
            } catch (error) {
                setIsValid(false);
                return navigate('/');
            }
        };
        getInfo();
        getClientData();

    }, []);

    // center navbar and content when is mobile device
    const handleDocType = () => {
        switch(info.TIPO_PRODUCTO_ID){
            case 8: return <Semblanza info={info} fontSize={fontSize}/>;
            case 12: return <Documento info={info} fontSize={fontSize} />;
            case 13: return <Documento info={info} fontSize={fontSize}/>;
            default: return navigate('/');
        }
    };

    return (
        <>
            {(loading===false)?(
                handleDocType()
            ):(
                <Container>
                    <Row>
                        <Col lg={12} md={12}>
                            <h1>Cargando...</h1>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
}

export { Info };